import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { styleOnlyProps } from 'Style/style-helpers';

// Utils
import Attribution from 'Utils/content/attribution';
import { getAuthorUrl } from 'Utils/content/flipboard-urls';
import DateUtil from 'Utils/date-util';
import { USAGE_EVENT_NAMES } from 'Utils/analytics/usage';
import { SPACING } from 'Style/spacing';
import { TEXT_COLORS } from 'Style/colors';

// Components
import WebLink from '../web-link';
import AuthorAttribution from './author';
import AuthorAvatar from './author-avatar';
import MagazineAttribution from './magazine';

import connector from 'Utils/connector';
import connectLanguage from 'Webapp/shared/app/connectors/connectLanguage';

import Bullet from 'Webapp/shared/app/components/bullet';

import withT from 'ComponentLibrary/hocs/withT';

export const StyledPostAttributionTime = styled(
  'time',
  styleOnlyProps('isNote'),
)(({ isNote }) => ({
  color: isNote ? TEXT_COLORS.secondaryReverse : TEXT_COLORS.tertiary,
  marginTop: isNote ? SPACING.SMALL : 0,
}));

export const StyledPostAttributionDecorator =
  StyledPostAttributionTime.withComponent('span');

const StyledAuthorWrapper = styled.div(({ showMagazine }) => {
  if (showMagazine) {
    return {
      display: 'block',
      lineHeight: '1',
    };
  }

  return {
    display: 'inline',
  };
});

const AttributionContainer = styled.div({
  overflow: 'hidden',
  wordBreak: 'break-word',
});

function PostAttribution(props) {
  const {
    className,
    item,
    section,
    showMagazineAttribution,
    shouldShowTime,
    shownInList,
    avatarSize,
    lang,
    t,
    isNote,
  } = props;

  const FlippedDetails = styled.div({
    marginRight: SPACING.LARGE,
  });

  const flipper = Attribution.getFlipper(item);
  if (flipper === null) {
    return <div className="post-attribution post-attribution--empty" />;
  }

  const magazine = Attribution.getMagazine(item);
  const sectionMatchesMag = magazine
    ? Attribution.sectionMatchesMagazine(section, magazine)
    : false;

  const showMagazine =
    showMagazineAttribution && magazine && !(shownInList && sectionMatchesMag);

  const itemPostDate = isNote ? item.dateCreated : item.dateFlipped;

  return (
    <div className={`${className} post-attribution ui-text--supporting`}>
      <WebLink
        className="media-link"
        href={getAuthorUrl(flipper)}
        navFromEventName={USAGE_EVENT_NAMES.SECTION_ENTER}
      >
        <AuthorAvatar author={flipper} avatarSize={avatarSize} />
      </WebLink>
      <AttributionContainer className="post-attribution__text">
        <StyledAuthorWrapper showMagazine={showMagazine}>
          <AuthorAttribution author={flipper} />
          {showMagazine && (
            <FlippedDetails>
              <StyledPostAttributionDecorator isNote={isNote}>
                {t('flipped_into')}
              </StyledPostAttributionDecorator>
              <MagazineAttribution magazine={magazine} />
              {shouldShowTime && itemPostDate && (
                <StyledPostAttributionTime isNote={isNote}>
                  <Bullet />
                  {DateUtil.friendlyFormat(itemPostDate, t, lang)}
                </StyledPostAttributionTime>
              )}
            </FlippedDetails>
          )}
          {!showMagazine && shouldShowTime && (
            <div>
              <StyledPostAttributionTime isNote={isNote}>
                {DateUtil.friendlyFormat(item.dateCreated, t, lang)}
              </StyledPostAttributionTime>
            </div>
          )}
        </StyledAuthorWrapper>
      </AttributionContainer>
    </div>
  );
}

PostAttribution.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object.isRequired,
  section: PropTypes.object,
  lang: PropTypes.string.isRequired,
  showMagazineAttribution: PropTypes.bool,
  shouldShowTime: PropTypes.bool,
  shownInList: PropTypes.bool,
  isNote: PropTypes.bool,
  avatarSize: PropTypes.number,
  t: PropTypes.func.isRequired,
};

PostAttribution.defaultProps = {
  section: null,
  showMagazineAttribution: true,
  shownInList: true,
};

export default connector(connectLanguage)(withT(PostAttribution));
