import React from 'react';
import PropTypes from 'prop-types';
import { USAGE_EVENT_NAMES } from 'Utils/analytics/usage';

import WebLink from '../web-link';
import Badge from 'Webapp/shared/app/components/badge';

import withT from 'ComponentLibrary/hocs/withT';
function MagazineAttribution({ magazine, showBadge, isOverlay, t }) {
  if (!magazine.title) {
    return null;
  }

  return (
    <WebLink
      href={magazine.canonicalPath}
      className="ui-body--small-standard magazine_attribution"
      navFromEventName={USAGE_EVENT_NAMES.SECTION_ENTER}
    >
      {showBadge && <Badge isOverlay={isOverlay}>{t('magazine')}</Badge>}
      {` ${magazine.title}`}
    </WebLink>
  );
}

MagazineAttribution.propTypes = {
  magazine: PropTypes.object.isRequired,
  section: PropTypes.object,
  showBadge: PropTypes.bool,
  isOverlay: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

MagazineAttribution.defaultProps = {
  section: null,
  showBadge: false,
  isOverlay: false,
};

export default withT(MagazineAttribution);
