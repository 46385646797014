import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { TEXT_COLORS, COLORS } from 'Style/colors';

// Utils
import Attribution from 'Utils/content/attribution';
import { getAuthorUrl } from 'Utils/content/flipboard-urls';
import { USAGE_EVENT_NAMES } from 'Utils/analytics/usage';

import WebLink from '../web-link';

const shouldForwardProp = (prop) => prop !== 'styleVariation';
export const AuthorLink = styled(WebLink, { shouldForwardProp })((props) => {
  if (props.styleVariation === STYLE_VARIATIONS.WHITE) {
    return { color: TEXT_COLORS.primary };
  }
  if (props.styleVariation === STYLE_VARIATIONS.RED) {
    return { color: COLORS.red };
  }
});
function AuthorAttribution({
  className,
  author,
  onProfileClick,
  styleVariation,
}) {
  const handleProfileClick = () => {
    if (onProfileClick) {
      onProfileClick();
    }
  };

  const authorName = Attribution.getAuthorName(author);
  // Nothing to render
  if (!authorName) {
    return null;
  }

  const profileUrl = getAuthorUrl(author);

  return (
    <AuthorLink
      className={className}
      styleVariation={styleVariation}
      href={profileUrl}
      onClick={handleProfileClick}
      navFromEventName={USAGE_EVENT_NAMES.SECTION_ENTER}
    >
      {authorName}
    </AuthorLink>
  );
}

export const STYLE_VARIATIONS = Object.freeze({
  WHITE: 'white',
  RED: 'red',
});

AuthorAttribution.propTypes = {
  className: PropTypes.string,
  author: PropTypes.object.isRequired,
  section: PropTypes.object,
  onProfileClick: PropTypes.func,
  styleVariation: PropTypes.oneOf(Object.values(STYLE_VARIATIONS)),
};

AuthorAttribution.defaultProps = {
  section: null,
  onProfileClick: null,
};

export default AuthorAttribution;
